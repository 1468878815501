var Velocity = require("velocity-animate");

document.addEventListener("DOMContentLoaded", function () {
    var iosPhone = this.querySelector(".js-ios");
    var androidPhone = this.querySelector(".js-android");
    Velocity(androidPhone, {
        "top": 0,
        "opacity": 1
    }, 1000);
    Velocity(iosPhone, {
        "top": 0,
        "opacity": 1
    }, {
        duration: 900,
        delay: 200
    });
});

var yellowLink = document.querySelectorAll(".link-y");

yellowLink.forEach(function (element) {
    var rect = element.querySelector('.link-y-rect');
    element.addEventListener("mouseenter", function () {
        Velocity(rect, {
            "height": "5px",
            "top": "30px",
            "left": "40px"
        }, 400);
    }, false);
    element.addEventListener("mouseleave", function () {
        Velocity(rect, "reverse", 400);
    }, false);
});

//Version two

var Swiper = require("swiper");

document.addEventListener("DOMContentLoaded", function () {
    var mySwiper = new Swiper('.swiper-container', {
        speed: 200,
        loop: true,
        slidesPerView: 1,
        centeredSlides: true,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
          },
        autoplay: {
            delay: 2000,
        }
    });
});